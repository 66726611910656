import React, { useMemo } from 'react';
import NextLink from 'next/link';

import { ButtonVariant, StyledButton } from '@/core/components/Button';
import { Heading, Subheadline } from '@/core/components/Typography';
import { routePaths } from '@/core/constants/routes';
import { Logo } from '@/core/components/Logo';

import { isG4S } from '@/utils/helpers';

import {
  Wrapper,
  Main,
  LogoContainer,
  PageContent,
  GradientCircleTopRight,
  GradientCircleBottom,
  StatusCode,
  HeadingWrapper,
  ParagraphWrapper,
  ButtonWrapper,
} from './Error.styles';
import { ErrorProps } from './Error.types';

export const Error = ({ statusCode }: ErrorProps) => {
  const headingToDisplay = useMemo(() => {
    switch (statusCode) {
      case 404:
        return "It looks, like you're lost";
      case 500:
        return 'Internal server error';
      default:
        return 'Error';
    }
  }, [statusCode]);

  const subheadingToDisplay = useMemo(() => {
    switch (statusCode) {
      case 404:
        return "The page you were looking for doesn't exist";
      case 500:
        return 'Something went wrong';
      default:
        return `Status code: ${statusCode}`;
    }
  }, [statusCode]);

  const logoSize = useMemo<{ width: number; height: number }>(() => {
    if (isG4S) {
      return {
        width: 140,
        height: 105,
      };
    }

    return {
      width: 140,
      height: 42,
    };
  }, []);

  return (
    <Main>
      <GradientCircleTopRight />
      <GradientCircleBottom />
      <PageContent>
        <LogoContainer>
          <Logo
            width={logoSize.width}
            height={logoSize.height}
          />
        </LogoContainer>
        <StatusCode>{statusCode}</StatusCode>
        <Wrapper>
          <HeadingWrapper>
            <Heading type="h1">{headingToDisplay}</Heading>
          </HeadingWrapper>
          <ParagraphWrapper>
            <Subheadline type="normal">{subheadingToDisplay}</Subheadline>
          </ParagraphWrapper>
          <ButtonWrapper>
            <NextLink
              href={routePaths.home}
              legacyBehavior
              passHref
            >
              <StyledButton
                as="a"
                variant={ButtonVariant.PRIMARY}
              >
                GO HOME
              </StyledButton>
            </NextLink>
          </ButtonWrapper>
        </Wrapper>
      </PageContent>
    </Main>
  );
};
