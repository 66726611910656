import styled from 'styled-components';

import { getVariant } from '@/themes/typographyUtilis';

export const Wrapper = styled.div`
  width: 453px;
  margin-top: 40px;
  text-align: center;
`;

export const HeadingWrapper = styled.div`
  margin-bottom: 8px;
`;

export const ParagraphWrapper = styled.div`
  margin-bottom: 40px;
  word-wrap: break-word;

  p {
    color: ${({ theme }) => theme.colors.secondaryText};
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 80px;
`;

export const Main = styled.main`
  position: relative;
  overflow: hidden;
  display: grid;
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.medium};
`;

export const GradientCircleTopRight = styled.div`
  position: absolute;
  top: -347px;
  right: -174px;
  z-index: 1;
  filter: blur(128px);
  width: 1068px;
  height: 1068px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(28, 70, 133, 0.5) 0%,
    rgba(28, 70, 133, 0) 100%
  );
`;

export const GradientCircleBottom = styled.div`
  position: absolute;
  top: 660px;
  left: calc(50% - 728px / 2 - 1px);
  z-index: 1;
  opacity: 0.5;
  filter: blur(128px);
  width: 728px;
  height: 728px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(28, 70, 133, 0.5) 0%,
    rgba(28, 70, 133, 0) 100%
  );
`;

export const PageContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: auto;
  padding-top: 100px;
`;

export const StatusCode = styled.p`
  color: ${({ theme }) => theme.colors.secondaryText};
  font-weight: 700;
  font-size: 204px;
  font-family: ${({ theme }) => theme.typography.fontFamily.inter};
  line-height: 246px;
`;

export const ButtonWrapper = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
    ${getVariant({ variant: 'body1-bold' })};
  }
`;
