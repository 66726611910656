import { NextPageContext } from 'next';

import { Error } from '@/features/Error';

type ErrorProps = {
  statusCode: number;
};

export default function ErrorPage({ statusCode }: ErrorProps) {
  return <Error statusCode={statusCode} />;
}

ErrorPage.hideFullLayout = true;

ErrorPage.getInitialProps = ({ res, err }: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode || 500 : 404;

  return {
    statusCode,
  };
};
